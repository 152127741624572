import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { RelativeTime } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { UserApi } from 'src/api';
import profilePlaceholder from 'src/assets/img/profile_placeholder.png';
import dictionary from 'src/constants/notifications/pl.json';
import { setNotificationAsRead } from 'src/features/NotificationsStore/actions';
import { changeOrdersVersion } from 'src/features/Orders/actions';
import { refreshOrder } from 'src/features/OrdersView/actions';
import { refreshValuations } from 'src/features/ValuationView/actions';
import { getFormattedAmount, imageErrorHandler } from 'src/utils/helpers';

import style from './NotificationCard.module.scss';

const specialFormatters = {
  new_price: getFormattedAmount,
  old_price: getFormattedAmount
};

const replaceExpression = (text, notification) => {
  const data = { ...notification, ...(notification?.data || {}) };
  return text.replaceAll(/<-(.*?)->/g, (match, key) => {
    const lowerCaseKey = key.toLowerCase();

    if (Object.prototype.hasOwnProperty.call(data, lowerCaseKey)) {
      if (Object.prototype.hasOwnProperty.call(specialFormatters, lowerCaseKey)) {
        return specialFormatters[lowerCaseKey](data[lowerCaseKey]);
      } else {
        return data[lowerCaseKey];
      }
    } else {
      console.warn(`Brak wartości dla klucza: ${lowerCaseKey}`);
      return match;
    }
  });
};

const NotificationCard = ({ notification: ntf, closeMenu, fixed, className, onRead, tiny, onBeforeRead }) => {
  const dispatch = useDispatch();
  const [notification, setNotification] = useState(ntf);
  const isAdminView = useSelector((state) => state.auth.isAdminView);

  const { type, read_at, id, data } = notification;
  const { created_at, thumbnail } = data || notification;

  const content = useMemo(() => {
    const urlRefreshFunctions = {
      '/valuations/<-UID->': () => dispatch(refreshValuations()),
      '/orders/<-UID->': () => {
        dispatch(refreshOrder());
        dispatch(changeOrdersVersion());
      }
    };

    if (dictionary[type]) {
      const { content, url, title } = dictionary[type];
      if (!content || !title) return null;

      return {
        text: replaceExpression(content, notification),
        url: replaceExpression(url, notification),
        title: replaceExpression(title, notification),
        refreshCallback: urlRefreshFunctions[url]
      };
    } else {
      return false;
    }
  }, [type]);

  const handleSetAsRead = async () => {
    if (isAdminView) {
      if (onBeforeRead) onBeforeRead({ ...notification, read_at: new Date().toISOString() });
      return;
    }

    try {
      if (onBeforeRead) onBeforeRead({ ...notification, read_at: new Date().toISOString() });
      await UserApi.setNotificationAsRead(id);
      dispatch(setNotificationAsRead(id));
      setNotification((prev) => ({
        ...prev,
        read_at: new Date().toISOString()
      }));
      if (closeMenu) closeMenu();
      if (onRead) onRead({ ...notification, read_at: new Date().toISOString() });
    } catch (err) {
      notifyApiError(err);
    }
  };

  useEffect(() => {
    setNotification(ntf);
  }, [ntf]);

  useEffect(() => {
    if (content.refreshCallback) content.refreshCallback();
  }, [content]);

  if (!content) {
    return null;
  }

  return (
    <Link
      to={content.url}
      className={classNames(style.link, className)}
      onClick={handleSetAsRead}
    >
      <div
        className={classNames(style.container, {
          [style.unread]: !read_at,
          [style.fixed]: fixed,
          [style.tiny]: tiny
        })}
      >
        <img
          className={classNames(style.image, { [style.tiny]: tiny })}
          src={thumbnail || profilePlaceholder}
          alt='avatar'
          onError={imageErrorHandler}
        />
        <div className={style.wrapper}>
          <RelativeTime
            date={created_at}
            className={classNames(style.timeAgo, { [style.tiny]: tiny })}
          />
          {!tiny && <h3 className={style.title}>{content.title}</h3>}
          <p className={style.text}>{content.text}</p>
        </div>
      </div>
    </Link>
  );
};

export default NotificationCard;
