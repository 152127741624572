import React from 'react';
import classnames from 'classnames';

import { ProductDetails, Tag, usePermissions } from 'components';

import { userPermissions } from 'src/constants/enums';

import AddNewPrice from '../../../AddNewPrice';
import OfferWrapper from '../../../OfferWrapper';
import RefPrice from '../../../RefPrice';
import ProductMenu from '../../components/ProductMenu';

import style from '../../Product.module.scss';

const ProductWithAllSuppliers = ({
  product,
  className,
  isCatalogView,
  selectedCompany,
  recentlyOrdered,
  showRefPrice = false,
  changeValuationSelections
}) => {
  const { ref_price: refPrice } = product || {};

  const boxClasses = classnames(style.box, style.withAll, { [style.withRefPrice]: showRefPrice });
  const [canMenageCatalog] = usePermissions([userPermissions.offer_catalog.menage]);

  return (
    <div className={boxClasses}>
      <ProductDetails
        className={className}
        product={product}
        showRefPrice={showRefPrice}
        orderedDate={recentlyOrdered}
        withoutSavings
        autoSize
      />
      <RefPrice
        price={refPrice}
        quantity={product.quantity}
        showRefPrice={showRefPrice}
        selectedCompany={selectedCompany}
        productId={product.product_id}
        product={product}
      />
      <div className={style.prices}>
        {product.prices?.map((price) => (
          <div
            key={price.id}
            className={style.priceWrapper}
          >
            <OfferWrapper
              item={price}
              product={product}
              changeValuationSelections={changeValuationSelections}
              selectedCompany={selectedCompany}
              isCatalogView={isCatalogView}
            />
          </div>
        ))}
        {!canMenageCatalog && product.prices.length === 0 && (
          <div className={style.priceWrapper}>
            <Tag value={'Brak ceny produktu'} />
          </div>
        )}
        {canMenageCatalog && (
          <div className={style.priceWrapper}>
            <AddNewPrice
              product={product}
              selectedCompany={selectedCompany}
            />
          </div>
        )}
      </div>
      <ProductMenu
        selectedCompany={selectedCompany}
        product={product}
      />
    </div>
  );
};

export default ProductWithAllSuppliers;
